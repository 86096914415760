<template lang="pug">
	.project-analytics
		b-tabs(nav-class="tabs-filled tabs-filled-transparent tabs-analytics" v-model="tab" no-fade)
			//- TAB SOURCES
			b-tab(:title="$t('project.analytics.sources')")
				.d-flex.flex-column.card-basic.pb-0
					.d-flex.flex-column.flex-md-row.justify-content-md-between.align-items-md-center
						span.b1.mb-3.mb-md-0 {{ $t('project.analytics.views') }}

						.d-flex
							.d-flex.position-relative.date-picker-control.date-picker-control--small
								v-date-picker.w-100.date-picker.date-picker--small(:key="apexchartKey" @input='selectDate' v-model="date" :min-date="new Date(item.createdAt)" :max-date="new Date()" :masks="masks" is-range :locale="currentLanguage" :popover="{ visibility: 'click' }")
									template(v-slot="{ inputValue, inputEvents, togglePopover }")
										span.icon-calendar(@click="togglePopover()")
										.d-flex.date-picker__input(@click="togglePopover()")
											input.b3.mr-1.date-picker__input-calendar.date-picker__input-calendar--first(:class="{'visually-hidden': !date.start && !date.end}" id="creation-date-calendar" :value="date.start | dateDDMMMYYDots" v-on="inputEvents.start" readonly)
											span.b3.mr-1(v-if="date.start && date.end") -
											span.b3.date-picker__input-calendar(v-if="date.start && date.end") {{ date.end | dateDDMMMYYDots }}
											input.visually-hidden(:value="date.end | dateDDMMMYYDots" v-on="inputEvents.end")
							dropdown-general.ml-2(:key="dropdownKey" :list="dropdownList" @onChange='changeGroup')

					.loader.primary.indent-loader(v-if="!metrics.items")

					.indent-empty.analytics-empty(v-if="metrics.items && metrics.items[0].viewsCount == 0")
						h1.mb-2.mt-5 😢
						span.b1.mb-5 {{ $t('project.noViews') }}

					.project-analytics__apexchart(v-if='metrics && metrics.items && metrics.items[0].viewsCount != 0')
						apexchart(type="line" height="375" :options="options" :series="series" :key="apexchartKey")

				.d-none.d-md-flex.flex-wrap.justify-content-between.justify-content-md-start.card-basic.project-analytics__wrap(v-if="tooltipMetricsValues.length > 0 && metrics.items && tab == 0  && metrics.items[0].viewsCount != 0")
					slot(v-for='(i, ind) in metrics.items')
						.simple-chart-legend.project-analytics__legend(v-if='!i.isSubCategory() && i._checked')
							.b3.simple-chart-legend-colored(:style='{"--color" : "#"+i.color}')
								b-form-checkbox(v-model='i._checked' @input='checkLegendItem(i), setChart()')
									| {{ i.getTitle($t('project.analytics.sourcesLegend')) }}
						.simple-chart-legend.project-analytics__legend(v-for='c in i.subCategory' :key='c.color' v-if='c._checked')
							.b3.simple-chart-legend-colored(:style='{"--color" : "#"+c.color}')
								b-form-checkbox(v-model='c._checked' @input='checkLegendItem(i), setChart()')
									| {{ c.getTitle($t('project.analytics.sourcesLegend')) }}

				.d-flex.flex-column.d-md-none.card-basic.mb-3.pb-2(v-if="tooltipMetricsValues.length > 0 && metrics.items && metrics.items[0].viewsCount != 0")
					.d-flex.justify-content-between.align-items-center.w-100.mb-2.position-relative(v-for="(i, ind) in tooltipMetricsValues")
						span.b3.text-grey.overflow-wrap.project-analytics__title {{ i.name }}
						span.b3 {{ i.viewsCount }}
						span.project-analytics__color(:style="{backgroundColor: `#${i.color}`}")

				table-analytics-sources.vuetable.table-metric(
					v-if="metrics.items && metrics.items[0].viewsCount != 0 && !isPending && tab == 0"
					:class="{'card-basic': !isMobile}"
					:hideSearch='true'
					:hidePerPage='true'
					@onChangeSubCategories='onChangeSubCategories'
				)

			//- TAB UTM
			b-tab(:title="$t('project.analytics.utm')")
				.d-flex.flex-column.card-basic.mb-3.mb-md-4.pb-0
					.d-flex.flex-column.flex-md-row.justify-content-md-between.align-items-md-center
						span.b1.mb-3.mb-md-0 {{ $t('project.analytics.views') }}

						.d-flex
							.d-flex.position-relative.date-picker-control.date-picker-control--small
								v-date-picker.w-100.date-picker.date-picker--small(:key="apexchartKey" @input='selectDateUTM' v-model="dateUTM" :min-date="new Date(item.createdAt)" :max-date="new Date()"  :masks="masks" is-range :locale="currentLanguage" :popover="{ visibility: 'click' }")
									template(v-slot="{ inputValue, inputEvents, togglePopover }")
										span.icon-calendar(@click="togglePopover()")
										.d-flex.date-picker__input(@click="togglePopover()")
											input.b3.mr-1.date-picker__input-calendar.date-picker__input-calendar--first(:class="{'visually-hidden': !dateUTM.start && !dateUTM.end}" id="creation-date-calendar" :value="dateUTM.start | dateDDMMMYYDots" v-on="inputEvents.start" readonly)
											span.b3.mr-1(v-if="dateUTM.start && dateUTM.end") -
											span.b3.date-picker__input-calendar(v-if="dateUTM.start && dateUTM.end") {{ dateUTM.end | dateDDMMMYYDots }}
											input.visually-hidden(:value="dateUTM.end | dateDDMMMYYDots" v-on="inputEvents.end")
							dropdown-general.ml-2(:key="dropdownKey" :list="dropdownList" @onChange="changeGroupUTM")

					.loader.primary.indent-loader(v-if="!metricsUtm.items")

					.indent-empty.analytics-empty(v-if="metricsUtm.items && metricsUtm.items[0].viewsCount == 0")
						h1.mb-2.mt-5 😢
						span.b1.mb-5 {{ $t('project.noViews') }}

					.project-analytics__apexchart(v-if='metricsUtm && metricsUtm.items && metricsUtm.items[0].viewsCount != 0')
						apexchart(type="line" height="375" :options="options" :series="seriesUTM" :key="apexchartKey")

				.d-none.d-md-flex.flex-wrap.justify-content-between.justify-content-md-start.card-basic.project-analytics__wrap(v-if="tooltipMetricsUTMValues.length > 0 && metricsUtm.items && tab == 1 && metricsUtm.items[0].viewsCount != 0")
					.simple-chart-legend.project-analytics__legend(v-for="(i, ind) in metricsUtm.items" v-if="i._checked")
						.b3.simple-chart-legend-colored(:style='{"--color" : "#"+i.color}')
							b-form-checkbox(v-model='i.checked' @change='setChartUTM' @input='checkLegendItem(i), setChartUTM()')
								| {{ i.getTitle($t('project.analytics.sourcesLegend')) }}

				.d-flex.flex-column.d-md-none.card-basic.mb-3.pb-2(v-if="tooltipMetricsUTMValues.length > 0 && metricsUtm.items && metricsUtm.items[0].viewsCount != 0")
					.d-flex.justify-content-between.align-items-center.w-100.mb-2.position-relative(v-for="(i, ind) in tooltipMetricsUTMValues")
						span.b3.text-grey.project-analytics__title {{ i.name }}
						span.b3 {{ i.viewsCount }}
						span.project-analytics__color(:style="{backgroundColor: `#${i.color}`}")

				table-analytics-utm.vuetable.table-metric-utm(
					v-if="metricsUtm.items && metricsUtm.items[0].viewsCount != 0 && !isPending && tab == 1"
					:class="{'card-basic': !isMobile}"
					:hideSearch='true'
					:hidePerPage='true'
					@onChangeSubCategoriesUTM='onChangeSubCategoriesUTM'
				)

			//- TAB MRR Recovery
			//b-tab(v-if="!item.isNeuro()" :title="$t('project.MRRRecovery')")
				table-analytics-mrr(:tab="tab")

		modal-metrics-help(:item='openedModal')
		modal-utm-help(:item='openedModalUTM')
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment-timezone';
import { STORAGE } from '@/api/storage';
import Metrics from '@/components/Tables/Metrics';
import MetricsUTM from '@/components/Tables/MetricsUTM';
import AnalyticsMRR from '@/components/Tables/AnalyticsMRR';
import ModalMetricsHelp from '@/components/Modals/ModalMetricsHelp';
import ModalUTMHelp from '@/components/Modals/ModalUTMHelp';
import HowItWorksAlert from '@/components/Cards/HowItWorksAlert';
import DropdownGeneral from '@/components/Dropdown/DropdownGeneral';
import { localePicker, PickerPeriods } from '@/constants/config';
import { METRIC_TYPES } from '@/models/metric';
import filters from '@/filters';
import { DEVICE } from '@/utils/device';

let globalPeriod = PickerPeriods.DAILY;
let globalPeriodUTM = PickerPeriods.DAILY;
const PERIODS = {
    DAILY: 'daily',
    WEEKLY: 'weekly',
    MONTHLY: 'monthly',
};

export default {
    name: 'ProjectAnalytics',
    components: {
        'table-analytics-sources': Metrics,
        'table-analytics-utm': MetricsUTM,
        'table-analytics-mrr': AnalyticsMRR,
        ModalMetricsHelp,
        HowItWorksAlert,
        'modal-utm-help': ModalUTMHelp,
        DropdownGeneral,
    },
    data: () => ({
        globalLabels: [],
        apexchartKey: 0,
        dropdownKey: 0,
        tab: 0,
        period: null,
        date: {
            start: new Date(moment().utc().subtract(6, 'days')),
            end: new Date(),
            period: null,
        },
        dateUTM: {
            start: new Date(moment().utc().subtract(6, 'days')),
            end: new Date(),
            period: null,
        },
        periodUTM: null,
        masks: {
            input: 'DD.MM.YYYY',
            weekdays: 'WW',
        },
        currentLanguage: STORAGE.getLang(),
        chartOptions: {
            stroke: {
                width: 1.5,
            },
            legend: {
                show: false,
            },
            tooltip: {
                style: {
                    fontSize: '14px',
                    fontFamily: 'Golos, Nunito, sans-serif',
                },

                x: {
                    format: 'dddd dd.MM.yy',
                },
                y: {
                    title: {
                        formatter(seriesName) {
                            return seriesName;
                        },
                    },
                },
            },
        },
        total: {},
        options: {},
        series: [],
        seriesUTM: [],
        dropdownList: [],
        filters: [
            {
                page: { value: 1 },
                itemsPerPage: { value: 1000 },
            },
        ],
        openedModal: true,
        openedModalUTM: false,
        tooltipMetricsValues: [],
        tooltipMetricsUTMValues: [],
        objGraphUTMData: {},
    }),
    created() {
        this.chartOptions.tooltip.x.formatter = this.formatter;
        this.getDropdownList();

        window.addEventListener('click', e => {
            if (this.isMobile) {
                const target = e.target;

                if (!target.closest('.apexcharts-grid')) {
                    this.setTooltipMetricsValue();
                    this.setTooltipMetricsUTMValue();
                }
            }
        });

        if (this.$route.query && this.$route.query.tab) {
            this.tab = this.$route.query.tab;
        }
    },
    computed: {
        ...mapGetters({
            item: 'project/opened',
            isPending: 'project/isPending',
            metrics: 'project/metrics',
            metricsUtm: 'project/metricsUtm',
            referrers: 'referrers/list',
        }),
        checkedUtmItems() {
            let out = [];
            _.each(this.metricsUtm.items, i => {
                out.push(i);
                out.push(...i.getChildrenTree());
            });

            return _.uniq(out);
        },
    },
    methods: {
        ...mapActions({
            edit: 'project/edit',
            getMetrics: 'project/metrics',
            getMetricsUTM: 'project/metricsUtm',
            getReferrers: 'referrers/items',
        }),
        findSource(ind) {
            let item = null;

            _.each(this.metrics.items, ii => {
                if (ii.categoryType == ind) {
                    item = ii;
                    return;
                }
                if (ii.hasSubCategory()) {
                    _.each(ii.subCategory, sc => {
                        if (sc.subCategoryId == ind) {
                            item = sc;
                            return;
                        }
                    });
                }
            });

            return item;
        },
        findUTM(ind) {
            let item = null;

            _.each(this.checkedUtmItems, ii => {
                if (ii.key == ind) {
                    item = ii;
                    return;
                }

                _.each(ii.children, sc => {
                    if (sc.key == ind) {
                        item = sc;
                        return;
                    }
                });
            });

            return item;
        },
        findMax(data) {
            let max = _.max(_.map(data, d => _.max(_.values(d.data))));
            return max == 0 ? 10 : Math.ceil(max * 1.05);
        },
        onChangeSubCategories() {
            this.setChart();
        },
        onChangeSubCategoriesUTM() {
            this.setChartUTM();
        },
        selectDate() {
            this.$store.commit('project/setMetrics', null);

            this.getMetrics({
                id: this.item.id,
                start: moment(this.date.start).format('YYYY-MM-DDT00:00:00'),
                end: moment(this.date.end).format('YYYY-MM-DDT23:59:59'),
                group: this.period,
                tz: moment.tz.guess(),
            }).then(this.setChart);
        },
        selectDateUTM() {
            this.$store.commit('project/setMetricsUtm', null);

            this.getMetricsUTM({
                id: this.item.id,
                start: moment(this.dateUTM.start).format('YYYY-MM-DDT00:00:00'),
                end: moment(this.dateUTM.end).format('YYYY-MM-DDT23:59:59'),
                group: this.periodUTM,
                tz: moment.tz.guess(),
            }).then(this.setChartUTM);
        },
        changeGroup(i) {
            this.period = i;
            globalPeriod = i;
            this.selectDate();
            this.apexchartKey++;
        },
        changeGroupUTM(i) {
            this.apexchartShow = false;
            this.periodUTM = i;
            globalPeriodUTM = i;
            this.selectDateUTM();
            this.apexchartKey++;
        },
        getDropdownList() {
            this.dropdownList = [
                {
                    id: 'daily',
                    title: this.$t('project.dropdownStatistics[0]'),
                },
                {
                    id: 'weekly',
                    title: this.$t('project.dropdownStatistics[1]'),
                },
                {
                    id: 'monthly',
                    title: this.$t('project.dropdownStatistics[2]'),
                },
            ];

            if (this.tab == 0) {
                this.changeGroup(this.dropdownList[0].id);
                this.periodUTM = this.dropdownList[0].id;
            } else if (this.tab == 1) {
                this.period = this.dropdownList[0].id;
                this.changeGroupUTM(this.dropdownList[0].id);
            }
        },
        getChartLabels() {
            let period = this.tab == 0 ? this.period : this.periodUTM;
            this.globalLabels = [];

            if (this.tab == 0) {
                let arrMetrics = Object.keys(this.metrics.graph.DIRECT);

                _.each(this.metrics.graph.DIRECT, (v, i) => {
                    if (period == PickerPeriods.WEEKLY && arrMetrics[0].substring(0, 4) != new Date().getFullYear()) {
                        this.globalLabels.push({ m: this.getDate(i, true) });
                    }

                    this.globalLabels.push({ m: this.getDate(i, false) });
                });
            } else if (this.tab == 1) {
                if (this.graphUTMData() && this.graphUTMData().TOTAL) {
                    this.objGraphUTMData = this.graphUTMData();
                    this.objGraphUTMData.DIRECT = this.objGraphUTMData.TOTAL;
                }

                let arrMetricsUtm = Object.keys(this.objGraphUTMData.DIRECT.data);

                _.each(this.objGraphUTMData.DIRECT.data, (v, i) => {
                    if (
                        period == PickerPeriods.WEEKLY &&
                        arrMetricsUtm[0].substring(0, 4) != new Date().getFullYear()
                    ) {
                        this.globalLabels.push({ m: this.getDate(i, true) });
                    }

                    this.globalLabels.push({ m: this.getDate(i, false) });
                });
            }

            if (PickerPeriods.HOURLY == period) {
                _.each(this.globalLabels, (v, i) => (this.globalLabels[i].l = v.m.format('H:00')));
                return _.map(this.globalLabels, (v, i) => v.l);
            }

            if (PickerPeriods.DAILY == period) {
                _.each(this.globalLabels, (v, i) => (this.globalLabels[i].l = v.m.format('D.MM')));
                return _.map(this.globalLabels, (v, i) => v.l);
            }

            if (PERIODS.WEEKLY == period) {
                _.each(this.globalLabels, (v, i) => {
                    let d = v.m.clone();
                    this.globalLabels[i].l = d.format('D.MM') + ' - ' + d.add(6, 'days').format('D.MM');
                });
                return _.uniq(_.map(this.globalLabels, (v, i) => v.l));
            }

            if (PickerPeriods.MONTHLY == period) {
                _.each(this.globalLabels, (v, i) => (this.globalLabels[i].l = v.m.format('MMM').slice(0, 3)));
                return _.map(this.globalLabels, (v, i) => v.l);
            }

            _.each(this.globalLabels, (v, i) => (this.globalLabels[i].l = v.m.format('D.MM')));
            return _.map(this.globalLabels, (v, i) => v.l);
        },
        getDate(d, v) {
            let period = this.tab == 0 ? this.period : this.periodUTM;
            let yw = d.split('-');

            if (period == PickerPeriods.WEEKLY)
                if (v) {
                    return moment(yw[0]).week(Number(yw[1] - 1));
                } else {
                    return moment(yw[0]).week(Number(yw[1]));
                }

            if (period == PickerPeriods.MONTHLY) return moment(yw[0]).month(Number(yw[1]) - 1);

            return moment(d);
        },
        formatter(value, { w }) {
            if (this.globalLabels && this.globalLabels[value - 1] && this.globalLabels[value - 1].m) {
                if (_.isString(value) || isNaN(value)) value = _.findIndex(this.globalLabels, g => g.l == value) + 1;

                let v = w.globals.categoryLabels[value - 1];
                let period = this.tab == 0 ? globalPeriod : globalPeriodUTM;

                if (PickerPeriods.DAILY == period) {
                    if (DEVICE.isMobile())
                        return (
                            `${filters.capitalize(
                                this.globalLabels[value - 1].m
                                    .format('dddd')
                                    .slice(0, 3)
                                    .replace(/[оеяу]/gi, '')
                            )} ${v}.` + this.globalLabels[value - 1].m.format('YY')
                        );

                    return (
                        `<span class='apexcharts-tooltip-pre-title'">${filters.capitalize(this.globalLabels[value - 1].m.format('dddd'))}</span> ${v}.` +
                        this.globalLabels[value - 1].m.format('YY')
                    );
                }

                if (PickerPeriods.MONTHLY == period) {
                    return (
                        this.globalLabels[value - 1].m.format('MMM').slice(0, 3) +
                        ' ' +
                        this.globalLabels[value - 1].m.format('YYYY')
                    );
                }

                // Удаляем одинаковые сохраняя порядок
                this.globalLabels = Object.values(
                    this.globalLabels.reduce(
                        (acc, n, i) => ((!acc[n.l] || (!acc[n.l][0].checked && n.checked)) && (acc[n.l] = [n, i]), acc),
                        {}
                    )
                )
                    .sort((a, b) => a[1] - b[1])
                    .map(n => n[0]);

                if (DEVICE.isMobile())
                    return `<span class="text-nowrap">${this.globalLabels[value - 1].l}<br>${this.globalLabels[value - 1].m._i}</span>`;

                return `<span class="text-nowrap">${this.globalLabels[value - 1].l} ${this.globalLabels[value - 1].m._i}</span>`;
            }
        },
        setChart() {
            let data = this.graphSourcesData();

            if (this.isMobile) delete this.chartOptions.tooltip.x.formatter;

            this.options = {
                ...this.chartOptions,
                colors: _.map(data, i => '#' + i.color),
                grid: {
                    borderColor: this.isDarkColor ? '#444' : '#E4E7EA',
                    padding: {
                        right: this.isMobile ? -5 : 0,
                    },
                },
                labels: this.getChartLabels(),
                tooltip: {
                    style: {
                        fontSize: '14px',
                        fontFamily: 'Golos, Nunito, sans-serif',
                    },
                    custom: ({ series, seriesIndex, dataPointIndex, w }) => {
                        let title = `<div class="apexcharts-tooltip-title">${this.formatter(dataPointIndex + 1, { w })}</div>`;

                        let items = '';
                        const ttItems = Array.from(w.globals.tooltip.ttItems);

                        ttItems.sort((a, b) => {
                            const valueA = a.getElementsByClassName('apexcharts-tooltip-text-y-value')[0].innerHTML;
                            const valueB = b.getElementsByClassName('apexcharts-tooltip-text-y-value')[0].innerHTML;

                            const numberA = parseInt(valueA);
                            const numberB = parseInt(valueB);

                            return numberB - numberA;
                        });

                        ttItems.forEach(ttItem => {
                            items = items + ttItem.outerHTML;
                        });

                        const customTooltipContent = document.createElement('div');
                        customTooltipContent.innerHTML = title + items;

                        return customTooltipContent.outerHTML;
                    },
                },
                xaxis: {
                    type: 'category',
                    tickPlacement: 'on',
                    labels: {
                        rotate: 0,
                        style: {
                            fontSize: '14px',
                            fontFamily: 'Golos, Nunito, sans-serif',
                            fontWeight: 400,
                            colors: this.isDarkColor ? '#fff' : '#082846',
                        },
                    },
                    axisTicks: {
                        show: false,
                    },
                    axisBorder: {
                        color: this.isDarkColor ? '#CBD2D8' : '#858B8F',
                    },
                    tickAmount:
                        this.isMobile && this.period == PERIODS.WEEKLY
                            ? 2
                            : this.globalLabels.length % 2 == 0
                              ? this.isMobile
                                  ? 4
                                  : 6
                              : this.isMobile
                                ? 5
                                : 7,
                    tooltip: {
                        enabled: this.isMobile ? true : false,
                        style: {
                            fontSize: '14px',
                            fontFamily: 'Golos, Nunito, sans-serif',
                        },
                        formatter: this.formatter,
                    },
                    crosshairs: {
                        show: true,
                        width: 2,
                        position: 'front',
                        opacity: 0.5,
                        stroke: {
                            color: this.isDarkColor ? '#808080' : '#8292A1',
                            width: 0,
                            dashArray: 0,
                        },
                    },
                },
                yaxis: [
                    {
                        seriesName: this.$t('project.views'),
                        tickAmount: this.findMax(data) < 10 ? this.findMax(data) : 10,
                        min: 0,
                        max: this.findMax(data),
                        labels: {
                            style: {
                                fontSize: '14px',
                                fontFamily: 'Golos, Nunito, sans-serif',
                                fontWeight: 400,
                                colors: this.isDarkColor ? '#808080' : '#8292A1',
                            },
                            formatter: val => {
                                return val.toFixed();
                            },
                        },
                    },
                ],
                chart: {
                    id: 'project-analytics',
                    offsetX: 5,
                    zoom: {
                        enabled: false,
                    },
                    toolbar: {
                        show: false,
                    },
                    locales: _.map(localePicker, l => {
                        return {
                            name: l.id,
                            options: {
                                months: l.monthNamesFull,
                                shortMonths: l.monthNames,
                                days: l.daysOfWeek,
                                shortDays: l.daysOfWeek,
                            },
                        };
                    }),
                    events: {
                        markerClick: (event, chartContext, config) => {
                            this.setTooltipMetricsValue(config);
                        },
                        mouseMove: (event, chartContext, config) => {
                            this.setTooltipMetricsValue(config);
                        },
                    },
                    defaultLocale: this.isLocaleRu ? 'ru' : 'en',
                },
            };
            this.series = _.map(data, (d, i) => {
                return {
                    techName: i,
                    name: METRIC_TYPES.includes(i) ? this.$t('project.analytics.sourcesLegend.' + i) : d.label,
                    data: _.values(d.data),
                };
            });

            this.setTooltipMetricsValue();
            this.apexchartKey++;
        },
        setTooltipMetricsValue(config = null) {
            let data = this.graphSourcesData();

            this.tooltipMetricsValues = [];

            _.each(this.series, s => {
                this.tooltipMetricsValues.push({
                    name: s.name,
                    viewsCount: config == null ? _.sum(_.values(data[s.techName].data)) : s.data[config.dataPointIndex],
                    color: data[s.techName].color,
                });
            });

            this.tooltipMetricsValues.sort((a, b) => {
                return b.viewsCount - a.viewsCount;
            });
        },

        setTooltipMetricsUTMValue(config = null) {
            let data = this.graphUTMData();

            this.tooltipMetricsUTMValues = [];

            _.each(this.seriesUTM, s => {
                this.tooltipMetricsUTMValues.push({
                    name: s.name,
                    viewsCount: config == null ? _.sum(_.values(data[s.techName].data)) : s.data[config.dataPointIndex],
                    color: data[s.techName].color ? data[s.techName].color : null,
                });
            });

            this.tooltipMetricsUTMValues.sort((a, b) => {
                return b.viewsCount - a.viewsCount;
            });
        },

        graphSourcesData() {
            let o = {};
            let out = {};
            if (!this.metrics || !this.metrics.items) return {};

            _.each(this.metrics.graph, (a, ind) => {
                o[ind] = {};
                let data = {};
                let item = this.findSource(ind);

                _.each(a, (d, indD) => {
                    data[indD] = d;
                });

                o[ind].data = data;

                if (item) {
                    o[ind].color = item.color;
                    o[ind].label = item.getTitle(this.$t('project.analytics.sourcesLegend'));
                }
            });

            this.setTotal(o);

            _.each(this.metrics.items, (a, ind) => {
                if (a.checked) {
                    if (a.categoryType && o[a.categoryType]) {
                        out[a.categoryType] = o[a.categoryType];
                    } else out[a.id] = o[a.id];
                } else {
                    _.each(a.subCategory, s => {
                        if (s.checked) {
                            if (s.categoryType && o[s.categoryType]) {
                                out[s.categoryType] = o[s.categoryType];
                            } else out[s.id] = o[s.id];
                        }
                    });
                }
            });

            delete out.null;
            return out;
        },
        graphUTMData() {
            let o = {};
            let out = {};
            if (!this.metricsUtm || !this.metricsUtm.items) return {};

            _.each(this.metricsUtm.graph, (a, ind) => {
                o[ind] = {};
                let data = {};
                let item = this.findUTM(ind);

                _.each(a, (d, indD) => {
                    data[indD] = d;
                });

                o[ind].data = data;

                if (item) {
                    o[ind].color = item.color;
                    o[ind].label = item.key;
                }
            });

            this.setTotalUTM(o);

            _.each(this.checkedUtmItems, (a, ind) => {
                if (a.checked) {
                    out[a.key] = o[a.key];
                }
            });

            delete out.null;
            return out;
        },
        setChartUTM() {
            let data = this.graphUTMData();

            this.options = {
                ...this.chartOptions,
                colors: _.map(data, i => '#' + i.color),
                grid: {
                    borderColor: this.isDarkColor ? '#444' : '#E4E7EA',
                    padding: {
                        right: this.isMobile ? -5 : 0,
                    },
                },
                labels: this.getChartLabels(),
                xaxis: {
                    tickPlacement: 'on',
                    labels: {
                        rotate: 0,
                        style: {
                            fontSize: '14px',
                            fontFamily: 'Golos, Nunito, sans-serif',
                            fontWeight: 400,
                            colors: this.isDarkColor ? '#fff' : '#082846',
                        },
                    },
                    axisTicks: {
                        show: false,
                    },
                    axisBorder: {
                        color: this.isDarkColor ? '#CBD2D8' : '#858B8F',
                    },
                    tooltip: {
                        enabled: this.isMobile ? true : false,
                        style: {
                            fontSize: '14px',
                            fontFamily: 'Golos, Nunito, sans-serif',
                        },
                        formatter: this.formatter,
                    },
                    tickAmount:
                        this.isMobile && this.period == PERIODS.WEEKLY
                            ? 2
                            : this.globalLabels.length % 2 == 0
                              ? this.isMobile
                                  ? 4
                                  : 6
                              : this.isMobile
                                ? 5
                                : 7,
                    crosshairs: {
                        show: true,
                        width: 2,
                        position: 'front',
                        opacity: 0.5,
                        stroke: {
                            color: this.isDarkColor ? '#808080' : '#8292A1',
                            width: 0,
                            dashArray: 0,
                        },
                    },
                },
                tooltip: {
                    style: {
                        fontSize: '14px',
                        fontFamily: 'Golos, Nunito, sans-serif',
                    },
                    custom: ({ series, seriesIndex, dataPointIndex, w }) => {
                        let title = `<div class="apexcharts-tooltip-title">${this.formatter(dataPointIndex + 1, { w })}</div>`;

                        let items = '';
                        const ttItems = Array.from(w.globals.tooltip.ttItems);

                        ttItems.sort((a, b) => {
                            const valueA = a.getElementsByClassName('apexcharts-tooltip-text-y-value')[0].innerHTML;
                            const valueB = b.getElementsByClassName('apexcharts-tooltip-text-y-value')[0].innerHTML;

                            const numberA = parseInt(valueA);
                            const numberB = parseInt(valueB);

                            return numberB - numberA;
                        });

                        ttItems.forEach(ttItem => {
                            items = items + ttItem.outerHTML;
                        });

                        const customTooltipContent = document.createElement('div');
                        customTooltipContent.innerHTML = title + items;

                        return customTooltipContent.outerHTML;
                    },
                },
                yaxis: [
                    {
                        seriesName: this.$t('project.analytics.views'),
                        tickAmount: this.findMax(data) < 10 ? this.findMax(data) : 10,
                        min: 0,
                        max: this.findMax(data),
                        labels: {
                            style: {
                                fontSize: '14px',
                                fontFamily: 'Golos, Nunito, sans-serif',
                                fontWeight: 400,
                                colors: this.isDarkColor ? '#808080' : '#8292A1',
                            },
                            formatter: val => {
                                return val.toFixed();
                            },
                        },
                    },
                ],
                chart: {
                    id: 'project-analytics',
                    offsetX: 5,
                    zoom: {
                        enabled: false,
                    },
                    toolbar: {
                        show: false,
                    },
                    locales: _.map(localePicker, l => {
                        return {
                            name: l.id,
                            options: {
                                months: l.monthNamesFull,
                                shortMonths: l.monthNames,
                                days: l.daysOfWeek,
                                shortDays: l.daysOfWeek,
                            },
                        };
                    }),
                    events: {
                        markerClick: (event, chartContext, config) => {
                            this.setTooltipMetricsUTMValue(config);
                        },
                        mouseMove: (event, chartContext, config) => {
                            this.setTooltipMetricsUTMValue(config);
                        },
                    },
                    defaultLocale: this.isLocaleRu ? 'ru' : 'en',
                },
            };
            this.seriesUTM = _.map(data, (d, i) => {
                return {
                    techName: i,
                    name: METRIC_TYPES.includes(i) ? this.$t('project.analytics.sourcesLegend.' + i) : d.label,
                    data: _.values(d.data),
                };
            });

            this.setTooltipMetricsUTMValue();
            this.apexchartKey++;
        },
        setTotal(o) {
            o['TOTAL'] = {
                data: {},
                color: this.metrics.items[0].color,
                label: this.$t('project.analytics.sourcesLegend')['TOTAL'],
            };

            _.each(o, (v, k) => {
                if (METRIC_TYPES.includes(k)) {
                    _.each(k != 'TOTAL' && v.data, (p, pi) => {
                        if (o['TOTAL'].data[pi]) o['TOTAL'].data[pi] += Number(p);
                        else o['TOTAL'].data[pi] = Number(p);
                    });
                }
            });
        },
        setTotalUTM(o) {
            o['TOTAL'] = {
                data: {},
                color: this.metricsUtm.items[0].color,
                label: this.$t('project.analytics.sourcesLegend')['TOTAL'],
            };

            _.each(o, (v, k) => {
                _.each(v.data, (p, pi) => {
                    if (k != 'TOTAL' && v.label && v.label.split('->').length == 1) {
                        if (o['TOTAL'].data[pi]) o['TOTAL'].data[pi] += Number(p);
                        else o['TOTAL'].data[pi] = Number(p);
                    }
                });
            });
        },
        checkLegendItem(item) {
            if (item.isSubCategory()) {
                let p = _.find(this.items, i => i.categoryType == item.categoryType && !i.isSubCategory());
                if (p) p._checked = false;
            } else item.checked = item._checked;
        },
    },
    watch: {
        tab(nv) {
            this.globalLabels = [];
            this.period = 'daily';
            this.periodUTM = 'daily';
            this.globalPeriod = 'daily';
            this.globalPeriodUTM = 'daily';
            this.apexchartKey++;

            if (nv == 0) {
                this.dropdownKey++;
                this.$store.commit('project/setMetrics', null);
                this.date.start = new Date(moment().utc().subtract(6, 'days'));
                this.date.end = new Date();
                this.date.period = null;
                this.chartOptions.tooltip.x.formatter = this.formatter;
                this.changeGroup(this.dropdownList[0].id);
            }
            if (nv == 1) {
                this.dropdownKey++;
                this.$store.commit('project/setMetricsUtm', null);
                this.dateUTM.start = new Date(moment().utc().subtract(6, 'days'));
                this.dateUTM.end = new Date();
                this.dateUTM.period = null;
                this.chartOptions.tooltip.x.formatter = this.formatter;
                this.changeGroupUTM(this.dropdownList[0].id);
            }
            this.$emit('tabChanged', nv);
        },
    },
};
</script>

<style lang="scss">
@import '~@/assets/css/sass/_mixins.scss';

.apexcharts-xaxis-texts-g {
    transform: translate(15px, 10px) scale(0.96);

    @include respond-below(sm) {
        transform: translate(15px, 30px) scale(0.89);
    }
}

.project-analytics {
    &__apexchart {
        position: relative;
        margin: 0 0 0 -26px;
        max-height: 380px !important;

        .apexcharts-tooltip {
            @include respond-below(sm) {
                display: none !important;
            }
        }
    }

    &__wrap {
        padding: 0 15px 9.5px 15px;
        margin-top: 21px;
        margin-bottom: 20px;
    }

    &__legend {
        margin-top: 20px;
        margin-right: 20px;
        top: -5px;
        position: relative;

        &:last-child {
            margin-right: auto;
        }

        .custom-control-label {
            padding-top: 4px;
            padding-left: 13px;
            color: var(--grey-text-color);
        }
    }

    &__title {
        @include respond-below(sm) {
            width: max-content;
            padding-left: 18px;
            margin-right: 8px;
        }
    }

    &__color {
        @include respond-below(sm) {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            width: 10px;
            height: 10px;
            border-radius: 50%;
        }
    }

    .table {
        tbody tr td:nth-child(2) {
            display: table-cell;
        }

        .checkbox-metric {
            display: flex;
            align-items: center;
            height: 24px;

            .custom-control-input {
                top: 0;
            }

            .custom-control-label {
                padding-top: 0;
                height: 24px;
            }
        }
    }
}
</style>
