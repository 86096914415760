<template lang="pug">
	b-modal#modal-analytics-help(ref='modal' size='md' hide-footer hide-header :centered='!isMobile')
		span.btn-close(aria-label="Close" @click="close")
			i.fontello.fontello-icon-cross

		.modal-scrollable-body.mb-4
			h2.mb-4.modal-xs-title {{ $t('project.analytics.howToUse.utm[0].title') }}
			.lh-18
				p {{ $t('project.analytics.howToUse.utm[0].desc') }}
				p {{ $t('project.analytics.howToUse.utm[0].desc2') }}
				p
					span.font-weight-medium UTM Source&nbsp;
					| - {{ $t('project.analytics.howToUse.utm[0].utmSourceDesc') }}
				p
					span.font-weight-medium UTM Medium&nbsp;
					| - {{ $t('project.analytics.howToUse.utm[0].utmMediumDesc') }}
				p
					span.font-weight-medium UTM Campaign&nbsp;
					| - {{ $t('project.analytics.howToUse.utm[0].utmCampaignDesc') }}
				p
					span.font-weight-medium UTM Content&nbsp;
					| — {{ $t('project.analytics.howToUse.utm[0].utmContentDesc') }}
				p.mb-4
					span.font-weight-medium UTM Term&nbsp;
					| - {{ $t('project.analytics.howToUse.utm[0].utmTermDesc') }}
					

				h2 {{ $t('project.analytics.howToUse.utm[1].title') }}
				p {{ $t('project.analytics.howToUse.utm[1].desc') }}

				ul
					li utm_source
					li utm_medium
					li utm_campaign
					li utm_content
					li utm_term

				p {{ $t('project.analytics.howToUse.utm[1].desc1') }}
				p.mb-4 {{ $t('project.analytics.howToUse.utm[1].desc2') }}

				h2 {{ $t('project.analytics.howToUse.utm[2].title') }}
				p {{ $t('project.analytics.howToUse.utm[2].desc') }}
				p {{ $t('project.analytics.howToUse.utm[2].desc1') }}
				p.text-break {{ paymentLink }}?utm_source=telegram&utm_medium=post&utm_campaign=somechannel&utm_content=obzor_1
				p {{ $t('project.analytics.howToUse.utm[2].desc2') }}&nbsp;
					span.font-weight-medium obzor_1&nbsp;
					| {{ $t('project.analytics.howToUse.utm[2].desc3') }}&nbsp;
					span.font-weight-medium somechannel
				p {{ $t('project.analytics.howToUse.goodluck') }}
		
		b-btn.btn-long.btn-xs-100(variant='primary' @click='close') {{ $t('social.understand') }}
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    name: 'ModalUTMHelp',
    props: {
        item: {
            default: false,
            type: Boolean,
        },
    },
    components: {},

    data: () => ({}),

    mounted() {},

    created() {},

    computed: {
        ...mapGetters({
            project: 'project/opened',
        }),
        paymentLinkDefault() {
            return this.project ? `${this.paymentLinkDomain}${this.project.hashId}` : '';
        },
        paymentLink() {
            return this.project
                ? `${window.location.protocol}//${window.location.host.replace(/(admin\.)|(merchant\.)/, '')}/${this.project.hashIdCustom}`
                : '';
        },
        paymentLinkDomain() {
            return `${window.location.protocol}//${window.location.host.replace(/(admin\.)|(merchant\.)/, '')}/`;
        },
    },

    methods: {
        close() {
            this.$refs.modal.hide();
        },
        show() {
            this.$refs.modal.show();
        },
    },

    watch: {
        item(v) {
            if (v) this.show();
        },
    },
};
</script>
