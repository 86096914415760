<template lang="pug">
	extends ./table.pug

	block tableTH
		template(v-slot:cell(year)='data')
			span.b3(v-if="data.item.year!==undefined") {{ months[data.item.year] }}

		template(v-slot:cell(atRisk)='data')
			span.b3(v-if="data.item.atRisk !== undefined") {{ data.item.atRisk }}

		template(v-slot:cell(underRestoration)='data')
			span.b3(v-if="data.item.onRecovery !== undefined") {{ data.item.onRecovery }}

		template(v-slot:cell(recovered)='data')
			span.b3(v-if="data.item.recovered !== undefined") {{ data.item.recovered }}

		template(v-slot:cell(outFlow)='data')
			span.b3(v-if="data.item.outFlow !== undefined") {{ data.item.outFlow }}

		template(v-slot:cell(rr)='data')
			span.b3(v-if="data.item.recovered !== undefined") {{ data.item.atRisk > 0 ? Math.round(data.item.recovered / data.item.atRisk * 100): 0 }}

		template(v-slot:cell(mrr)='data')
			span.analytics-mrr__list-item(v-if="data.item?.mrr && data.item.mrr.length > 0" v-html="data.item.mrr[0].priceWithCurrency()") {{ data.item.mrr[0].priceWithCurrency() }}

		template(v-slot:cell(mrrOther)='data')
			span.analytics-mrr__list-item(v-if="data.item?.mrr && data.item.mrr.length > 0" v-html="data.item.mrr[1].priceWithCurrency()") {{ data.item.mrr[0].priceWithCurrency() }}
</template>

<script>
import TableMixin from './TableMixin';
import TableSearch from './TableSearch';
import TableShowMore from './TableShowMore';

export default {
    name: 'RestoredIncomeTable',
    mixins: [TableMixin],
    components: {
        TableSearch,
        TableShowMore,
    },
    props: {
        items: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            noLocalSorting: false,
            localSorting: false,
            totalItems: 0,
            months: this.$t('project.analytics.fullNameMonths'),
            fields: [
                {
                    key: 'year',
                    label: '',
                },
                {
                    key: 'atRisk',
                    label: this.$t('plan.subscriptionsAtRisk'),
                    sortable: true,
                },
                {
                    key: 'onRecovery',
                    label: this.$t('plan.nextPaymentInSupportShort'),
                    sortable: true,
                },
                {
                    key: 'recovered',
                    label: this.$t('plan.recoveredShort'),
                    sortable: true,
                },
                {
                    key: 'outFlow',
                    label: this.$t('plan.outflow'),
                    sortable: true,
                },
                {
                    key: 'rr',
                    label: 'RR',
                    sortable: true,
                },
                {
                    key: 'mrr',
                    label: 'MRR 🇷🇺',
                    sortable: true,
                },
                {
                    key: 'mrrOther',
                    label: 'MRR 🌍',
                    sortable: true,
                },
            ],
        };
    },
};
</script>

<style>
.table-warning,
.table-warning > th,
.table-warning > td {
    background-color: #fffbea !important;
}
</style>
