<template lang="pug">
	.line-chart
		apexchart(type="bar" height="150" :options="chartOptions" :series="series")

		modal-tooltip(
			:id="'on-recovery'"
			:title="titleOnRecovery"
			:classTooltip="'analytics-tooltip'"
			:textMobile='textMobile'
		)

</template>

<script>
import { DEVICE } from '@/utils/device';
import ModalTooltip from '@/components/Modals/ModalTooltip';

export default {
    name: 'LineChart',
    components: {
        ModalTooltip,
    },
    props: {
        recoveryPayment: {
            type: Object,
            default: () => ({}),
        },
        recoveryComebacker: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            titleOnRecovery: '',
            numberOfSubscriptions: 0,
            textMobile: '',
            series: [
                {
                    name: this.$t('project.analytics.comebackerPaymentExpected'),
                    data: [this.recoveryComebacker.onRecovery.numberSubscriptions],
                },
                {
                    name: this.$t('project.analytics.paymentProcessingWithErrors'),
                    data: [this.recoveryPayment.onRecovery.numberSubscriptions],
                },
            ],
            chartOptions: {
                chart: {
                    type: 'bar',
                    stacked: true,
                    stackType: '100%',
                    toolbar: {
                        show: false,
                    },
                    sparkline: {
                        enabled: true,
                    },
                    events: {
                        dataPointSelection: (event, chartContext, config) => {
                            if (DEVICE.isMobile()) {
                                if (config.seriesIndex == 0) {
                                    this.titleOnRecovery = `<span class="d-flex align-items-center"><span class="status-rotate-left mr-10 at-icon"></span>${this.$t('promotions.tabs.comebacker')}</span>`;
                                    this.numberOfSubscriptions = this.series[0].data[0];
                                    this.$bvModal.show('on-recovery');
                                    this.textMobile = `<span><span class="text-grey">${this.$t('project.analytics.subscriptions')}: </span>${this.recoveryComebacker.onRecovery.numberSubscriptions}</span><span class="d-block at-income"><span class="text-grey at-income-txt">MRR: </span><span class="at-list-item">${this.recoveryComebacker.onRecovery.mrr[0].priceWithCurrency()}</span><span class="at-list-item">${this.recoveryComebacker.onRecovery.mrr[1].priceWithCurrency()}</span><span class="at-list-item">${this.recoveryComebacker.onRecovery.mrr[2].priceWithCurrency()}</span></span>`;
                                } else if (config.seriesIndex == 1) {
                                    this.titleOnRecovery = `<span class="d-flex align-items-center"><span class="status-health mr-10 at-icon"></span>${this.$t('project.analytics.paymentProcessing')}</span>`;
                                    this.numberOfSubscriptions = this.series[1].data[0];
                                    this.$bvModal.show('on-recovery');
                                    this.textMobile = `<span><span class="text-grey">${this.$t('project.analytics.subscriptions')}: </span>${this.recoveryPayment.onRecovery.numberSubscriptions}</span><span class="d-block at-income"><span class="text-grey at-income-txt">MRR: </span><span class="at-list-item">${this.recoveryPayment.onRecovery.mrr[0].priceWithCurrency()}</span><span class="at-list-item">${this.recoveryPayment.onRecovery.mrr[1].priceWithCurrency()}</span><span class="at-list-item">${this.recoveryPayment.onRecovery.mrr[2].priceWithCurrency()}</span></span>`;
                                }
                            }
                        },
                    },
                },
                stroke: {
                    width: 4,
                    colors: ['#fff'],
                },
                xaxis: {
                    labels: {
                        show: false,
                    },
                    axisBorder: {
                        show: false,
                    },
                    axisTicks: {
                        show: false,
                    },
                },
                yaxis: {
                    labels: {
                        show: false,
                    },
                    axisBorder: {
                        show: false,
                    },
                    axisTicks: {
                        show: false,
                    },
                },
                tooltip: {
                    enabled: !DEVICE.isMobile(),
                    style: {
                        fontSize: '14px',
                        fontFamily: 'Golos, Nunito, sans-serif',
                    },
                    y: {
                        title: {
                            formatter: (value, { series, seriesIndex, dataPointIndex, w }) => {
                                if (value == this.$t('project.analytics.comebackerPaymentExpected')) {
                                    return `<span class="d-flex align-items-center text-bold b1 at-header"><span class="status-rotate-left mr-10 at-icon"></span>${this.$t('promotions.tabs.comebacker')}</span><span><span class="text-grey">${this.$t('project.analytics.subscriptions')}: </span>${series[seriesIndex]}</span><span class="d-block at-income"><span class="text-grey at-income-txt">MRR: </span><span class="at-list-item">${this.recoveryComebacker.onRecovery.mrr[0].priceWithCurrency()}</span><span class="at-list-item">${this.recoveryComebacker.onRecovery.mrr[1].priceWithCurrency()}</span><span class="at-list-item">${this.recoveryComebacker.onRecovery.mrr[2].priceWithCurrency()}</span></span>`;
                                } else {
                                    return `<span class="d-flex align-items-center text-bold b1 at-header"><span class="status-health mr-10 at-icon"></span>${this.$t('project.analytics.paymentProcessing')}</span><span><span class="text-grey">${this.$t('project.analytics.subscriptions')}: </span>${series[seriesIndex]}</span><span class="d-block at-income"><span class="text-grey at-income-txt">MRR: </span><span class="at-list-item">${this.recoveryPayment.onRecovery.mrr[0].priceWithCurrency()}</span><span class="at-list-item">${this.recoveryPayment.onRecovery.mrr[1].priceWithCurrency()}</span><span class="at-list-item">${this.recoveryPayment.onRecovery.mrr[2].priceWithCurrency()}</span></span>`;
                                }
                            },
                        },
                    },
                },
                fill: {
                    opacity: 1,
                },
                legend: {
                    show: true,
                    position: 'bottom',
                    horizontalAlign: 'right',
                    markers: {
                        width: 10,
                        height: 10,
                        radius: 12,
                    },
                    onItemHover: {
                        highlightDataSeries: false,
                    },
                    onItemClick: {
                        toggleDataSeries: false,
                    },
                },
                grid: {
                    show: false,
                },
                colors: ['#AC7DD1', '#D0B182'],
                plotOptions: {
                    bar: {
                        horizontal: true,
                        barHeight: 26.5,
                    },
                },
            },
        };
    },
};
</script>

<style lang="scss">
@import '~@/assets/css/sass/_mixins.scss';

.line-chart {
    height: 100px;
    margin: -65px -2px 28px;

    @media (max-width: 1300px) {
        margin-bottom: 37px;
    }

    @include respond-below(sm-new) {
        margin-bottom: 28px;
        margin-top: -45px;
    }

    @include respond-below(xs) {
        margin-bottom: 37px;
    }

    .apexcharts-legend.apexcharts-align-right.apx-legend-position-bottom {
        padding: 0 !important;
        justify-content: flex-start !important;
        inset: auto 0 34.5px !important;

        @media (max-width: 1300px) {
            inset: auto 0 13px !important;
        }

        @include respond-below(sm-new) {
            inset: auto 0 34.5px !important;
        }

        @include respond-below(xs) {
            inset: auto 0 13px !important;
        }

        .apexcharts-legend-series {
            margin: 0 27px 0 2px !important;

            @media (max-width: 1300px) {
                width: 100%;
                margin: 0 0 10px 2px !important;
            }

            @include respond-below(sm-new) {
                margin: 0 27px 0 2px !important;
                width: auto;
            }

            @include respond-below(xs) {
                width: 100%;
                margin: 0 0 10px 2px !important;
            }

            &:last-of-type {
                margin: 0 0 0 2px !important;
            }
        }
    }

    .apexcharts-tooltip-title {
        display: none;
    }

    .apexcharts-tooltip-marker {
        display: none;
    }

    .apexcharts-tooltip-text-y-label {
        .at-list-item {
            position: relative;
            padding: 0 10px;

            &:after {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto 0;
                right: 0;
                width: 1px;
                height: 22px;
                background-color: var(--grayscale-dark-line);
            }

            &:last-of-type {
                padding-right: 0;

                &:after {
                    display: none;
                }
            }
        }

        .at-icon {
            width: 32px;
            height: 32px;
        }

        .at-header {
            margin-bottom: 13px;
        }

        .at-income {
            margin-top: 16px;
            margin-bottom: 3px;

            &-txt {
                margin-right: -3px;
            }
        }
    }

    .apexcharts-series {
        path {
            filter: none;
            stroke: var(--foreground-color) !important;
        }
    }
}
</style>
