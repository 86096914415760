<template lang="pug">
	.card-basic.events-processing
		span.d-block.b1.mb-10 {{ title }}

		.d-flex.align-items-center.justify-content-between.events-processing__header
			my-input-search.events-processing__search(
				v-model="query"
				:placeholder="$t('dashboards.search')"
			)

			dropdown-general.ml-auto.events-processing__dropdown(:list="dropdownList" :right="true" @onChange='changeStatus')

		table-events.vuetable(
			:hideSearch="true"
			:hidePerPage="true"
			:id="id"
			:items="items"
			:showPaginationBottom="true"
			:totalItems="totalItems"
			:currentPage="currentPage"
			:totalPages="totalPages"
			:perPage="perPage"
			@onChangeCurrentPage="changeCurrentPage"
		)
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import TableEvents from '@/components/Tables/TableEvents';
import MyInputSearch from '@/components/UI/MyInputSearch';
import PwBtnIcon from '@/components/Buttons/PwBtnIcon';
import DropdownGeneral from '@/components/Dropdown/DropdownGeneral';

export default {
    name: 'RestoredIncome',
    components: {
        TableEvents,
        MyInputSearch,
        PwBtnIcon,
        DropdownGeneral,
    },
    props: {
        id: {
            type: String,
            default: '',
        },
        items: {
            type: Array,
            default: () => [],
        },
        title: {
            type: String,
            default: '',
        },
        totalItems: {
            type: Number,
            default: 0,
        },
        currentPage: {
            type: Number,
            default: 0,
        },
        totalPages: {
            type: Number,
            default: 0,
        },
        perPage: {
            type: Number,
            default: 1,
        },
    },
    data: () => ({
        selectedStatus: null,
        query: null,
    }),
    computed: {
        ...mapGetters({
            item: 'project/opened',
        }),
    },
    created() {
        this.getDropdownList();
    },
    methods: {
        ...mapActions({
            getRecoveryEvents: 'comebackers/getRecoveryEvents',
            getRecoveryPaymentsEvents: 'comebackers/getRecoveryPaymentsEvents',
        }),
        changePageSize(perPage) {
            this.perPage = perPage;
        },
        changeCurrentPage(v) {
            if (this.id == 'recoveryEvents')
                this.getRecoveryEvents({
                    id: this.item.id,
                    itemsPerPage: this.perPage,
                    page: v,
                    status: this.selectedStatus,
                });

            if (this.id == 'recoveryPaymentsEvents')
                this.getRecoveryPaymentsEvents({
                    id: this.item.id,
                    itemsPerPage: this.perPage,
                    page: v,
                    status: this.selectedStatus,
                });
        },
        changeStatus(i) {
            this.selectedStatus = i;
            let args = { id: this.item.id, itemsPerPage: this.perPage, page: 1, status: this.selectedStatus };
            if (this.query !== null) {
                args.query = this.query;
            }

            if (this.id == 'recoveryEvents') this.getRecoveryEvents(args);

            if (this.id == 'recoveryPaymentsEvents') this.getRecoveryPaymentsEvents(args);
        },
        getDropdownList() {
            this.dropdownList = [
                {
                    id: null,
                    title: this.$t('create.dropdown[0]'),
                },
                {
                    id: 'onRecovery',
                    title: this.$t('plan.nextPaymentInSupport'),
                    color: 'is-error-processing is-orange',
                },
                {
                    id: 'recovered',
                    title: this.$t('plan.recovered'),
                    color: 'is-error-processing is-green',
                },
                {
                    id: 'cancel',
                    title: this.$t('plan.notRestored'),
                    color: 'is-error-processing is-grey',
                },
            ];
        },
    },
    watch: {
        query(nV, oV) {
            if (nV && nV.length > 2) {
                let args = { id: this.item.id, itemsPerPage: this.perPage, page: 1, query: nV };
                if (this.selectedStatus !== null) {
                    args.status = this.selectedStatus;
                }
                if (this.id == 'recoveryEvents') this.getRecoveryEvents(args);

                if (this.id == 'recoveryPaymentsEvents') this.getRecoveryPaymentsEvents(args);
            }
        },
    },
};
</script>

<style lang="scss">
@import '~@/assets/css/sass/_mixins.scss';

.events-processing {
    margin-top: 20px;

    @include respond-below(sm) {
        padding: 19px 17px;
    }

    &__header {
        margin-bottom: 20px;

        @include respond-below(xs) {
            justify-content: flex-start !important;
        }
    }

    &__search {
        max-width: 180px;
    }

    &__dropdown {
        height: 31.6px;

        button {
            height: 31.6px;
        }
    }
}
</style>
