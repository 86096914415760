<template lang="pug">

	extends ./table.pug

	block tableTH
		template(v-slot:cell(comebacker)='data')
			.td-line.simple-chart-legend-colored(:style='{background: "#"+data.item.color}' v-if='data.item.checked')
			.d-flex.align-items-center(:class='{"table-tr-children" : data.item?.plans}')
				i.cursor-pointer.fontello.text-primary(v-if='data.item?.plans'  @click='extendRow(data.item)' :class='{"simple-icon-plus" : !data.item.isExtended, "simple-icon-minus" : data.item.isExtended}')
				i.cursor-pointer.fontello.text-primary.simple-icon-plus.invisible(v-else)
				.simple-chart-legend--item.simple-chart-legend-colored(:style='{"--color" : "#" + (data.item.checked ? data.item.color : "52A7F9")}')
					span.d-flex.align-items-center
						b-form-checkbox.checkbox-metric(v-if='data.item.plans!==undefined' v-model="data.item.checked" @change="$emit('setChart')" )
						| {{ data.item.title }}
					span.is-tariff(v-if="false") {{ 'Пробная подписка' }}

				template(v-if="false")
					svg.ml-10(width='26' height='26' viewbox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg')
						rect(width='26' height='26' rx='4' :fill='isDarkColor ? "#232D38" : "#DDE8F3"')
						path(d='M18.625 11.6641V18.2491C18.625 19.7491 18.25 20.4991 16.375 20.4991H9.625C7.75 20.4991 7.375 19.7491 7.375 18.2491V11.6641' :stroke='isDarkColor ? "#808080" : "#8292A1"' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
						path(d='M18.25 5.5C19.75 5.5 20.5 6.25 20.5 7.75V9.25C20.5 10.75 19.75 11.5 18.25 11.5H7.75C6.25 11.5 5.5 10.75 5.5 9.25V7.75C5.5 6.25 6.25 5.5 7.75 5.5H18.25Z' :stroke='isDarkColor ? "#808080" : "#8292A1"' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
						path(d='M11.6348 14.5H14.3648' :stroke='isDarkColor ? "#808080" : "#8292A1"' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')

		template(v-slot:cell(atRisk)='data')
			span.b3(v-if="data.item?.metrics.atRisk") {{ data.item.metrics.atRisk }}

		template(v-slot:cell(onRecovery)='data')
			span.b3(v-if="data.item?.metrics.onRecovery") {{ data.item.metrics.onRecovery }}

		template(v-slot:cell(recovered)='data')
			span.b3(v-if="data.item?.metrics.recovered") {{ data.item.metrics.recovered }}

		template(v-slot:cell(outFlow)='data')
			span.b3(v-if="data.item?.metrics.outFlow") {{ data.item.metrics.outFlow }}

		template(v-slot:cell(rr)='data')
			span.b3(v-if="data.item?.metrics.recovered") {{ Math.round(data.item.metrics.recovered / data.item.metrics.atRisk * 100) }} %

		template(v-slot:cell(mrr)='data')
			span.analytics-mrr__list-item(v-if="data.item?.metrics.mrr && data.item.metrics.mrr.length > 0" v-html="data.item.metrics.mrr[0].priceWithCurrency()") {{ data.item.metrics.mrr[0].priceWithCurrency() }}

		template(v-slot:cell(mrrOther)='data')
			span.analytics-mrr__list-item(v-if="data.item?.metrics.mrr && data.item.metrics.mrr.length > 0" v-html="data.item.metrics.mrr[1].priceWithCurrency()") {{ data.item.metrics.mrr[1].priceWithCurrency() }}
</template>

<script>
import TableMixin from './TableMixin';
import TableSearch from './TableSearch';
import TableShowMore from './TableShowMore';
import TablePagination from './TablePagination';
import ModalTooltip from '@/components/Modals/ModalTooltip';

export default {
    name: 'MetricsTable',
    mixins: [TableMixin],
    props: {
        filters: {
            type: Object,
            default: () => ({}),
        },
        type: {
            type: String,
            default: null,
        },
        items: {
            type: Array,
            default: () => [],
        },
        series: {
            type: Array,
            default: () => [],
        },
        selectAll: {
            type: Boolean,
            default: true,
        },
    },
    components: {
        TableSearch,
        TablePagination,
        TableShowMore,
        ModalTooltip,
    },
    data() {
        return {
            noLocalSorting: true,
            localSorting: false,
            totalItems: 0,
            fields: [
                {
                    key: 'comebacker',
                    label: 'comebacker',
                },
                {
                    key: 'atRisk',
                    label: this.$t('plan.subscriptionsAtRisk'),
                },
                {
                    key: 'onRecovery',
                    label: this.$t('plan.nextPaymentInSupportShort'),
                },
                {
                    key: 'recovered',
                    label: this.$t('plan.recoveredShort'),
                },
                {
                    key: 'outFlow',
                    label: this.$t('plan.outflow'),
                },
                {
                    key: 'rr',
                    label: 'RR',
                },
                {
                    key: 'mrr',
                    label: 'MRR 🇷🇺',
                },
                {
                    key: 'mrrOther',
                    label: 'MRR 🌍',
                },
            ],
            isbusy: false,
        };
    },
    methods: {
        extendRow(item) {
            this.$store.commit('comebackers/metricExpand', item);
        },
    },
    computed: {
        lineColors() {
            return ['#87D7B0', '#FF7C7C', '#7F7CFF', '#FCB64D', '#E478CD', '#A4B20E', '#0E6DB2', '#6FA5B1', '#CA7A68'];
        },
    },
};
</script>

<style lang="scss" scoped>
.fontello {
    font-size: 20px;
}

.table {
    th {
        .fontello-icon-question {
            &:before {
                background: transparent;
                border-radius: 50%;
            }
        }
    }
}

.table-tr-children,
.table-tr-children0 {
    --p: 20px;

    padding-left: var(--p);
    .td-line {
        left: calc(var(--p) + 2px) !important;
    }

    &.table-tr-children1 {
        --p: 42px;
    }
    &.table-tr-children2 {
        --p: 60px;
    }
    &.table-tr-children3 {
        --p: 80px;
    }
    &.table-tr-children4 {
        --p: 100px;
    }
}

@media (max-width: 767.98px) {
    .cursor-pointer.mr-4 {
        margin-right: 16px !important;
    }
}
</style>
