<template lang="pug">
	b-alert.cursor-pointer.how-it-works-notification(show variant='primary' @click='click' :class='{"how-it-works-notification-transparent" : transparent}')
		i.fontello.fontello-icon-question.mr-2(@click='click')
		span(@click='click') {{ t }}
</template>

<script>
export default {
    name: 'HowItWorksAlert',
    props: {
        title: {
            type: String,
            default: '',
        },
        transparent: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        t: '',
    }),
    created() {
        if (this.title == '') return (this.t = this.$t('finance.howItsWork'));
        this.t = this.title;
    },
    methods: {
        click(i) {
            this.$emit('onClick', i);
        },
    },
};
</script>

<style scoped lang="scss">
@media (max-width: 767.98px) {
    .how-it-works-notification {
        border-radius: 0 !important;
    }
}
</style>
